<template>
  <div class="box">
    <img src="../../assets/img/logo.png" alt="" class="logoImg">
    <div class="content">
      <p class="title"></p>
      <p class="info1">国际公建设计艺术展WORKPLACE（以下简称WORKPLACE）是SZCW于2020年新增加的子展。</p>
      <div class="btn-box">
        <van-button type="info" size="normal" color="#048DAD" class="btn" @click="toSelect(0)">优选品牌</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { getState } from '@/api/accentFurnitures.js';
  export default {
    data() {
      return {

      }
    },
    created() {
      document.title = '国际公建设计艺术展';
      // let {source = '', unionid = ''} = this.$route.query;
      // localStorage.setItem('source', source)
      // localStorage.setItem('unionid', unionid)
      // unionid && this.getStatus()
    },
    methods: {
      getStatus() {
        let unionid = localStorage.getItem('unionid')
        let params = {
          unionid: unionid
        }
        getState(params).then(res => {
          if (res.data != null) {
            this.$router.push({
              path: '/otherResult'
            })
          }
        })
      },
    },
  }
</script>

<style scoped>
  .box {
    background-image: url('../../assets/img/baground.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: .4rem;
  }

  .logoImg {
    width: 40%;
    display: block;
    margin: 10vh auto 6vh;
  }
  .content {
    width: 83%;
    margin: 0 auto;
  }
  .title {
    font-size: .26rem;
  }

  .info1 {
    font-size: .14rem;
    margin-top: .2rem;
    margin-bottom: .2rem;
    text-indent: 2em;
    line-height: .22rem;
    text-align: justify;
  }
  .btn-box {
    margin-top: 8vh;
  }
  .btn {
    font-size: .16rem;
    height: .4rem;
    border-radius: .05rem;
    width: 60%;
    margin: 0 auto 5vh;
    display: block;
  }

</style>
