import request from './request';
// import qs from 'qs';
// 提交信息
export function submitInfo(data) {
	return request({
		url: '/h5/business/matchmaking/save',
		method: 'post',
		data
	})
}
// 获取信息详情
export function getState(params) {
	return request({
		url: '/h5/business/matchmaking/detail',
		method: 'post',
		params
	})
}
// 获取信息详情
export function queryInfo() {
	return request({
		url: '/h5/enroll/info',
		method: 'post'
	})
}